export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'
export const PAYMENT_TYPE = {
  CC: 'Credit_Card',
  ECP: 'ECP',
}

export const ACCOUNT_TYPE = {
  savings: 'Savings',
  checking: 'Checking',
  commercialChecking: 'Commercial Checking',
}

export const MOBILE_WEBVIEW_ACCESS = 'mobile_webview_access' // to determine if mobile webview
