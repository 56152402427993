import React from 'react'
import Media from 'react-media'
import { breakpoints } from 'styles/mq'
import { Flex } from 'grid-styled'
import { Text } from 'components/text'
import Button from 'components/buttons/Button'
import { Trans } from '@lingui/macro'
import { companyInfo, color, features } from 'config/profile'
import OnlineSupportStatus from './OnlineSupportStatus/index.js'
import TrustPilotRating from './TrustPilotRating/index.js'

import {
  StyledHeader,
  HeaderBackground,
  GriddyLogo,
  Wrapper,
  LangContainer,
} from './styled'
import LangButton from '../../../components/buttons/LangButton'

const LinkLogo = () => (
  <a href={companyInfo.homepageLink} target="_blank" rel="noopener noreferrer">
    <GriddyLogo />
  </a>
)

const isMobileQuery = {
  maxWidth: breakpoints.tablet - 1,
}

const Header = () => (
  <StyledHeader>
    <HeaderBackground>
      <Flex>
        <LinkLogo />
        {features.TRUSTPILOT_HEADER && <TrustPilotRating />}
      </Flex>
      <Media query={isMobileQuery}>
        {isMobile => (
          <Wrapper>
            {!isMobile && features.CALL_HEADER && (
              <Flex alignItems="center" mr={15}>
                <Flex mr={15} flexDirection="column" alignItems="center">
                  <Text color={color.bannerTextColor}>
                    <Trans>Call us with your questions</Trans>
                  </Text>
                  <Text extraSmall color={color.bannerTextColor}>
                    {companyInfo.callingHours}
                  </Text>
                </Flex>

                <Button>{companyInfo.phone}</Button>
              </Flex>
            )}
            {features.ONLINE_SUPPORT_STATUS_HEADER && <OnlineSupportStatus />}
            {features.LOCALE_HEADER && (
              <LangContainer {...features.LOCALE_HEADER.props}>
                <Flex {...features.LOCALE_HEADER.props}>
                  <LangButton />
                </Flex>
              </LangContainer>
            )}
          </Wrapper>
        )}
      </Media>
    </HeaderBackground>
  </StyledHeader>
)

export default Header
